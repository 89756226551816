import React, { Component } from 'react'

import SEO from '../../components/SEO/SEO'
import Layout from '../../Layout/Layout'
import Hero from '../../components/Hero/Hero'
import Header from '../../components/Header/Header'
import Subheader from '../../components/Subheader/Subheader'
import Container from '../../components/Container/Container'

import HeroImage from '../../assets/images/thumb5.jpg'

class BlogPost extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Rozszerzamy zasięg świadczenia naszych usług"
          keywords={[
            'rzeszów',
            'wynajem',
            'zwyżka',
            'zwyżka rzeszów',
            'wynajem zwyżki rzeszów',
            'prace na wysokościach rzeszów',
            'podnośnik rzeszów',
            'podnośnik',
            'zwyżka teleskopowa',
            'tarnów',
            'zwyżka tarnów',
            'podkarpackie',
            'małopolskie',
          ]}
        />
        <Hero image={HeroImage}>
          <Header>Rozszerzamy zasięg świadczenia naszych usług</Header>
          <Subheader>
            W związku ze zwiększeniem naszej floty specjalistycznych urządzeń do
            pracy na wysokościach, pragniemy poinformować, że zwiększamy obszar, 
            na którym świadczymy nasze usługi.
          </Subheader>
        </Hero>
        <Container columns={1}>
          <p>
            Główna siedziba, parking maszyn, oraz magazyny naszej firmy znajdują się 
            w Bojanowie w powiecie stalowowolskim. Jest to miejscowość położona w 
            północnej części województwa podkarpackiego.
          </p>
          <p>
            Jest to bardzo dobry punkt pod względem logistycznym, który pozwala nam 
            świadczyć usługi wynajmu zwyżek i podnośników na terenie aż trzech 
            województw - podkarpackiego, lubelskiego i świętokrzyskiego. Jako, że 
            poczyniliśmy w ostatnim czasie spore inwestycje w zwiększenie naszej floty 
            specjalistycznych pojazdów jesteśmy w stanie dojechać teraz również do powiatów 
            położonych na południe od Rzeszowa, oraz do wschodnich powiatów województwa małopolskiego.
          </p>
          <p>
            Cenę usługi zawsze ustalamy indywidualnie. Zależy ona od wielu czynników. Jednym z nich jest 
            również odległość, którą musimy pokonać aby do Państwa dojechać. Ze względu na dogodne położenie 
            Bojanowa, jesteśmy w stanie oferować wypożyczenie zwyżki i podnośnika w optymalnych cenach zarówno 
            dla mieszkańców Rzeszowa, Stalowej Woli i Tarnobrzega, jak i Janowa Lubelskiego, Mielca czy Tarnowa.
          </p>
          <p>
            Jesteśmy również jedną z niewielu firm na tym obszarze, która oferuje wynajęcie zwyżki umożliwiającej 
            pracę na wysokości do 28 metrów. Wszystkie nasze zwyżki i podnośniki są maszynami najwyższej jakości, 
            a obsługują je przeszkoleni fachowcy.
          </p>
          <p>
            Zapraszamy do kontaktu.
          </p>

        </Container>
      </Layout>
    )
  }
}

export default BlogPost
